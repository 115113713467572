import React from "react";

const TeamCard = ({ members }) => {
  return (
    <>
      {members.map((val) => (
        <div className='items shadow' key={val.id}>
          <div className='img'>
            <img src={`https://bethanybils.pythonanywhere.com${val.image}`} alt={val.name} />
            {/* <div className='overlay'>
              <i className='fab fa-facebook-f icon'></i>
              <i className='fab fa-twitter icon'></i>
              <i className='fab fa-instagram icon'></i>
              <i className='fab fa-tiktok icon'></i>
            </div> */}
          </div>
          <div className='details'>
            <h2>{val.name}</h2>
            <p>{val.designation}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default TeamCard;
