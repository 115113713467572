import React from "react"
import Back from "../common/back/Back"
import Photo from "./Photo"

const A = () => {
    return (
        <>
            <Back title='Gallery' />
            <section className='blog '>
                <div className='container '>

                    <Photo />

                </div>
            </section>
        </>
    )
}

export default A
