import React, { useState } from 'react';
import axios from 'axios';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://bethanybils.pythonanywhere.com/api/contactform/', formData, {
        headers: {
          'Content-Type': 'application/json'
        }

      });
      
      if (response.status === 200) {
        setStatus('Message sent successfully!');
        // Clear form if needed
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: ''
        });
      }
    } catch (error) {
      setStatus('There was a problem with your submission: ' + error.message);
    }
    
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='flexSB'>
        <input
          type='text'
          name='name'
          placeholder='Name'
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          type='email'
          name='email'
          placeholder='Email'
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <input
        type='text'
        name='subject'
        placeholder='Subject'
        value={formData.subject}
        onChange={handleChange}
        required
      />
      <textarea
        name='message'
        cols='30'
        rows='10'
        placeholder='Create a message here...'
        value={formData.message}
        onChange={handleChange}
        required
      />
      <button type='submit' className='primary-btn'>SEND MESSAGE</button>
      {status && <p>{status}</p>}
    </form>
  );
};

export default ContactForm;
