import "./App.css";
import Header from "./components/common/header/Header";
import {
  BrowserRouter as Router,
  Routes,
  Switch,
  Route,
} from "react-router-dom";
import About from "./components/about/About";
import CourseHome from "./components/allcourses/CourseHome";
import Team from "./components/team/Team";
import Pricing from "./components/pricing/Pricing";
import Blog from "./components/blog/Blog";
import BlogLayout from "./components/blog/Indi_blog";

import Contact from "./components/contact/Contact";
import Footer from "./components/common/footer/Footer";
import Home from "./components/home/Home";
// import TestComponent from "./components/Test";
import { ContactProvider } from "./components/contact/ContactContext";
import { BlogProvider } from "./components/blog/BlogContext";
import Gallery from "./components/gallery/Gallery";
import { useEffect, useState } from "react";

function App() {
  const [count, setCount] = useState(0);
  useEffect(function () {
    console.log(count);
  }, []);

  const ContactWrapper = () => (
    <ContactProvider>
      <Contact />
    </ContactProvider>
  );

  const BlogWrapper = () => (
    <BlogProvider>
      <BlogLayout />
    </BlogProvider>
  );

  const FooterWrapper = () => (
    <BlogProvider>
      <ContactProvider>
        <Footer />
      </ContactProvider>
    </BlogProvider>
  );

  return (
    <>
      <Router basename="/">
        {/* basename="/school" */}
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/courses" element={<CourseHome />} />
          <Route exact path="/team" element={<Team />} />
          <Route path="/news" element={<Blog />} />
          <Route exact path="/pricing" element={<Pricing />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/blog/:id" element={<BlogWrapper />} />
          <Route exact path="/contact" element={<ContactWrapper />} />
        </Routes>
        <FooterWrapper />
      </Router>
    </>
  );
}

export default App;
