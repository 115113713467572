import React, { createContext, useState, useEffect } from "react";
import axios from 'axios'

export const ContactContext = createContext();

export const ContactProvider = ({ children }) => {
    const [contacts, setContacts] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        const fetchContact = async () => {
            try {
                const response = await axios.get("https://bethanybils.pythonanywhere.com/api/contactus/")
                setContacts(response.data)
            } catch (error) {
                console.error("Error fetching contact details");
                setError("Failed to get contact")
            }
            finally{
                setLoading(false)
            }
        }
        fetchContact()
    }, [])
    return(
        <ContactContext.Provider value={{contacts,loading,error}}>
            {children}
        </ContactContext.Provider>
    )
}