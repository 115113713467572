import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const BlogContext = createContext()

export const BlogProvider = ({ children }) => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get("https://bethanybils.pythonanywhere.com/api/blog/");
                setBlogs(response.data)
            } catch (error) {
                console.error("Error fetching blog data", error);
                setError("Failed to load blogs")
            }
            finally {
                setLoading(false)
            }
        }
        fetchBlogs();
    }, [])

    return(
        <BlogContext.Provider value={{blogs,loading,error}}>
            {children}
        </BlogContext.Provider>
    )

}