export const homeAbout = [
  {
    id: 1,
    cover: "https://img.icons8.com/dotty/80/000000/language.png", // Represents language learning.
    title: "OUR INSTITUTE",
    desc: "Bethany Institute for Language Studies (BILS), Thiruvalla, offers a seamless and affordable pathway to mastering the German language. Our students consistently achieve excellent results, especially at the B2 level.",
  },
  {
    id: 2,
    cover: "https://img.icons8.com/ios/80/000000/training.png", // Represents experienced faculty.
    title: "OUR VISION",
    desc: "Our highly qualified instructors are passionate about teaching and committed to your success, bringing years of expertise to ensure a comprehensive understanding of the language.",
  },
  {
    id: 3,
    cover: "https://img.icons8.com/ios/80/000000/conference-call.png", // Represents interactive sessions.
    title: "INTERACTIVE SESSIONS",
    desc: "Our Vision is to impart linguistic proficiency in German .Here you will see a door wide open for you to walk through and make learning German as easy as possible. With a strong base in the language, our faculty will help you make your dream come true.",
  },
];

export const awrapper = [
  {
    cover: "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
    data: "3,000",
    title: "STUDENTS",
  },

  {
    cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
    data: "320",
    title: "TRUSTED TUTORS",
  },
  {
    cover: "https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png",
    data: "1,000",
    title: "SCHEDULES",
  },
  {
    cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
    data: "587",
    title: "TROPIES",
  },
]
export const coursesCard = [
  {
    id: 1,
    cover: "../images/courses/c1.png",
    coursesName: "Introducing to German Language",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by John Smith",
        totalTime: "50 lectures (190 hrs)",
      },
    ],
    priceAll: "$100 All Course",
    pricePer: "$15 per month",
  },
  {
    id: 2,
    cover: "../images/courses/c2.png",
    coursesName: "Enhancing Language Skills",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Ram Gurung",
        totalTime: "30 lectures (125 hrs)",
      },
    ],
    priceAll: "$200 All Course",
    pricePer: "$25 per month",
  },
  {
    id: 3,
    cover: "../images/courses/c3.png",
    coursesName: "qwuhdhuwbd iwdhhfd hf",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Saroj Nepal",
        totalTime: "50 lectures (50 hrs)",
      },
    ],
    priceAll: "$50 All Course",
    pricePer: "$5 per month",
  },
  // {
  //   id: 4,
  //   cover: "../images/courses/c4.png",
  //   coursesName: "Introducing to Programming with WordPress",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Prabin Thapa Mager",
  //       totalTime: "20 lectures (20 hrs)",
  //     },
  //   ],
  //   priceAll: "$30 All Course",
  //   pricePer: "$3 per month",
  // },
  // {
  //   id: 5,
  //   cover: "../images/courses/c5.png",
  //   coursesName: "Introducing to Programming with ReactJS",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Sunil Bishowkaram",
  //       totalTime: "100 lectures (150 hrs)",
  //     },
  //   ],
  //   priceAll: "$300 All Course",
  //   pricePer: "$30 per month",
  // },
  // {
  //   id: 6,
  //   cover: "../images/courses/c6.png",
  //   coursesName: "Learn Frontend Programming Language",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Sunil Bishowkaram",
  //       totalTime: "200 lectures (300 hrs)",
  //     },
  //   ],
  //   priceAll: "$500 All Course",
  //   pricePer: "$80 per month",
  // },
  // {
  //   id: 7,
  //   cover: "../images/courses/c7.png",
  //   coursesName: "Introducing to with HTML / CSS",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Price",
  //       totalTime: "20 lectures (50 hrs)",
  //     },
  //   ],
  //   priceAll: "$20 All Course",
  //   pricePer: "$2 per month",
  // },
  // {
  //   id: 8,
  //   cover: "../images/courses/c8.png",
  //   coursesName: "Introducing to with JAVA",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Petter",
  //       totalTime: "80 lectures (200 hrs)",
  //     },
  //   ],
  //   priceAll: "$200 All Course",
  //   pricePer: "$40 per month",
  // },
  // {
  //   id: 9,
  //   cover: "../images/courses/c9.png",
  //   coursesName: "Introducing to with HTML / CSS / JAVASCRIPT",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Petter",
  //       totalTime: "50 lectures (50 hrs)",
  //     },
  //   ],
  //   priceAll: "$20 All Course",
  //   pricePer: "$3 per month",
  // },
]
export const online = [
  {
    cover: "./images/courses/online/o1.png",
    hoverCover: "./images/courses/online/o1.1.png",
    courseName: "xxxxx xxxx",
    course: "25 Courses",
  },
  {
    cover: "./images/courses/online/o2.png",
    hoverCover: "./images/courses/online/o2.1.png",
    courseName: "xxxx xxxx",
    course: "25 Courses",
  },
  {
    cover: "./images/courses/online/o3.png",
    hoverCover: "./images/courses/online/o3.1.png",
    courseName: "xxxxx",
    course: "10 Courses",
  },
  {
    cover: "./images/courses/online/o4.png",
    hoverCover: "./images/courses/online/o4.1.png",
    courseName: "xxxxxx",
    course: "15 Courses",
  },
  {
    cover: "./images/courses/online/o5.png",
    hoverCover: "./images/courses/online/o5.1.png",
    courseName: "xxxxxx",
    course: "30 Courses",
  },
  {
    cover: "./images/courses/online/o6.png",
    hoverCover: "./images/courses/online/o6.1.png",
    courseName: "xxxxx",
    course: "60 Courses",
  },
  // {
  //   cover: "./images/courses/online/o7.png",
  //   hoverCover: "./images/courses/online/o7.1.png",
  //   courseName: "Health & Fitness",
  //   course: "10 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o8.png",
  //   hoverCover: "./images/courses/online/o8.1.png",
  //   courseName: "Marketing",
  //   course: "30 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o9.png",
  //   hoverCover: "./images/courses/online/o9.1.png",
  //   courseName: "Graphic Design",
  //   course: "80 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o10.png",
  //   hoverCover: "./images/courses/online/o10.1.png",
  //   courseName: "Music",
  //   course: "120 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o11.png",
  //   hoverCover: "./images/courses/online/o11.1.png",
  //   courseName: "Business Administration",
  //   course: "17 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o12.png",
  //   hoverCover: "./images/courses/online/o12.1.png",
  //   courseName: "Web Management",
  //   course: "17 Courses",
  // },
]
export const team = [
  {
    cover: "./images/team/t1.webp",
    name: "Ph.D Adrian Molises",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t2.webp",
    name: "Ph.D Arthur MaGregor",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t3.webp",
    name: "Ph.D Anna Hanzen",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t4.webp",
    name: "Ph.D Brian Wooden",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t5.webp",
    name: "Ph.D Adrian Molises",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t6.webp",
    name: "Ph.D Arthur MaGregor",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t7.webp",
    name: "Ph.D Anna Hanzen",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t8.webp",
    name: "Ph.D Brian Wooden",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
]
export const price = [
  {
    name: "BASIC PLAN",
    price: "49K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "BEGINNER PLAN",
    price: "79K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "PREMIUM PLAN",
    price: "109k",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "ULTIMATE PLAN",
    price: "149K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
]
export const faq = [
  {
    title: "How to Enroll This Online Courses?",
    desc: "This is the first item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "Where It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How to Enroll This Online Courses?",
    desc: "This is the first item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "Where It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
]
export const blog = [
  {
    id: 1,
    type: "admin",
    date: "JAN. 18, 2021",
    com: "3 COMMENTS ",
    title: "Avid Reader's Programme",
    desc: "To foster the reading habits of the students, a special reading programme called avid reading programme is conducted.",
    cover: "../images/blog/b1.webp",
  },
  {
    id: 2,
    type: "admin",
    date: "API. 25, 2022",
    com: "5 COMMENTS ",
    title: "The Hindu Quiz",
    desc: "To improve the general knowledge of our students, The Hindu Quiz is conducted.",
    cover: "../images/blog/b2.webp",
  },
  {
    id: 3,
    type: "user",
    date: "MAY. 15, 2022",
    com: "10 COMMENTS ",
    title: "Aakash Coaching",
    desc: "To make our students excellent in the field of different competitive as well as entrance examinations",
    cover: "../images/blog/b3.webp",
  },
  {
    id: 4,
    type: "admin",
    date: "JAN. 02, 2022",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b4.webp",
  },
  {
    id: 5,
    type: "admin",
    date: "DEC. 14, 2022",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b5.webp",
  },
  {
    id: 6,
    type: "user",
    date: "JAN. 18, 2021",
    com: "12 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b6.webp",
  },
]
export const testimonal = [
  {
    id: 1,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t1.webp",
  },
  {
    id: 2,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t2.webp",
  },
  {
    id: 3,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t3.webp",
  },
]
