import React from "react"
import img from "../../../img/BILS_logo-removebg-preview.png"

const Head = () => {
  return (
    <>
      <section className='head'>
        <div className='container flexSB'>
          <div className='logo'>
            <img src={img} alt="" style={{ width: '100px' }} />
          </div>

          <div className='social'>
            <a href='https://www.facebook.com/bethany.institute.for.language.studies/' target='_blank' rel='noopener noreferrer'>
              <i className='fab fa-facebook-f icon'></i>
            </a>
            <a href='https://www.instagram.com/learngermaninbils?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==' target='_blank' rel='noopener noreferrer'>
              <i className='fab fa-instagram icon'></i>
            </a>
            {/* Uncomment if you want Twitter */}
            {/* <a href='https://twitter.com' target='_blank' rel='noopener noreferrer'>
    <i className='fab fa-twitter icon'></i>
  </a> */}
            <a href='http://www.youtube.com/@BILS-BethanylanguageSchool' target='_blank' rel='noopener noreferrer'>
              <i className='fab fa-youtube icon'></i>
            </a>
          </div>

        </div>
      </section>
    </>
  )
}

export default Head